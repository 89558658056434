<template>
  <div class="ebooks">
    <heads></heads>
    <div
      class="cen_box"
      :style="{
        backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
      }"
    >
      <ul>
        <li
          v-for="(item, index) in ebooksCategory"
          :key="index"
          v-if="item.is_container_ebook == 1"
        >
          <router-link
            :to="{
              path: path,
              query: { id: item.id },
            }"
            class="items"
          >
            {{ item.name }}
          </router-link>
        </li>
      </ul>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import { getbookCategorys } from "@api/public";

export default {
  components: {
    heads,
    foot,
  },
  props: {},
  data() {
    return {
      backgroud: "",
      path: "",
      ebooksCategory: [],
    };
  },
  mounted: function () {
    let that = this;
    getbookCategorys({ id: 1 }).then(function (res) {
      that.$set(that, "ebooksCategory", res.data);
    });
  },
  created: function () {
    document.title = this.$route.meta.title;
    this.backgroud = this.$route.meta.background;
    this.path = this.$route.meta.path;
  },
};
</script>
<style>
.ebooks > .cen_box {
  width: 100%;
  height: 1032px;
  background-size: cover;
  background-position: center 0;
}

.ebooks > .cen_box ul {
  top: 260px;
  width: 800px;
  height: 545px;
  position: relative;
  left: 550px;
  display: flex;
  justify-content: space-between;
}

.ebooks > .cen_box li {
  width: 113px;
  height: 100%;
  display: flex;
  align-items: center;
  writing-mode: vertical-lr;
  font-family: kaiti;
  box-sizing: border-box;
  background: url(../../assets/images/ebooksCategoryItemBg.png) no-repeat;
  background-size: 113px 551px;
}

.ebooks > .cen_box li a {
  color: #000;
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.ebooks > .cen_box li:first-child {
  margin-left: 20px;
}
.ebooks > .cen_box li:last-child {
  margin-right: 20px;
}

.items {
  color: #000;
  text-decoration: none;
  display: block;
  font-size: 26px;
  margin: 15px;
  font-family: "kaiti";
  font-weight: bold;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
}
</style>